const recos = {
  list: {
    priceSizeOverride: {
      price: 24,
      discount: 12,
      installments: 12,
      original: 12,
    },
  },
};

module.exports = {
  BUTTON_SEARCH_ID: 'cart-btn-search',
  BUTTON_LANDING: 'landing-button',
  CARD_FEATURED_ID: 'card-featured',
  CAROUSEL_FEATURED_ID: 'carousel-featured',
  CARD_PURCHASED_ID: 'card-purchased',
  EMPTY_BUTTON_CART: 'recos-empty-btn-cart',
  EMPTY_BUTTON_SEARCH: 'recos-empty-btn-search',
  EMPTY_SUBTITLE: 'recos-empty-subtitle',
  EMPTY_TITLE: 'recos-empty-title',
  FREE_SHIPPING_RECOS_ID: 'free-shipping-cart-recommendations',
  MLT_RECOS_ID: 'more-like-this-recommendations',
  PERSO_RECOS_ID: 'perso-landing',
  POM_RECOS_ID: 'pom-recommendations',
  PROGRESSBAR_ID: 'cart-full-progress',
  RECOS: recos,
  RMKT_RECOS_ID: 'remarketing-cart-recommendations',
  CAROUSEL_ID: 'carousels',
  LIST_RECOMMENDATIONS: 'list-recommendations',
  HOME_RECOS_ID: 'home-landing-recommendations',
  SUBTITLE_ID: 'cart-subtitle',
  TABS_RECOS: 'tabs',
  RECOS_ERROR_HANDLED: 'recos-error-handled',
  RECOS_EMPTY_STATE: 'recos-empty-state',
  POM_ADN_ID: 'CONTEXT_UP',
};
