/* eslint-disable react/jsx-fragments */
const React = require('react');
const Script = require('nordic/script');
const { string, shape } = require('prop-types');
const Section = require('../../../commons/section');
const withTracker = require('../../../commons/with-tracker');
const { POM_ADN_ID } = require('../../recos-landings/shared/constants');

const namespace = 'advertising';

function AdvertisingAdn(props) {
  const { id, content } = props;
  const [isBannerEmpty, setIsBannerEmpty] = React.useState(false);
  const ref = React.useRef(null);

  React.useEffect(() => {
    const handleBannerEmpty = () => setIsBannerEmpty(true);
    const node = ref.current;

    if (node) {
      node.addEventListener('adn-banner-empty', handleBannerEmpty);
    }

    return () => {
      if (node) {
        node.removeEventListener('adn-banner-empty', handleBannerEmpty);
      }
    };
  }, []);

  return (
    <Section type="adn" className={id === POM_ADN_ID ? `${namespace}-pom-line-${!isBannerEmpty}` : 'AD_ADN_area'}>
      <Script src="https://http2.mlstatic.com/frontend-assets/adn-frontend-library/adn-script.js" />
      <div
        data-component="adn"
        data-content={JSON.stringify(content)}
        ref={ref}
      />
    </Section>
  );
}

AdvertisingAdn.propTypes = {
  id: string,
  content: shape({
    category: string,
    page: string,
    placement: string,
    platform: string,
    site_id: string,
    slot_id: string,
    title: string,
  }).isRequired,
};

module.exports = withTracker(AdvertisingAdn);
